import { Link } from "react-router-dom";
import "./navbar.css";
import { BarsOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
const Navbar = (props) => {
  const [show, setshow] = useState(false);
  const [stickyClass, setStickyClass] = useState("");
  const [updateWidth, setUpdateWidth] = useState(170);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const menuRef = useRef();

  let isLogged = "no";

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Attach the event listener to update dimensions when the window is resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY) {
        setIsScrollingDown(true);
      } else {
        setUpdateWidth(145);
        setIsScrollingDown(false);
      }

      setPrevScrollY(currentScrollY);
    };

    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      setUpdateWidth(140);
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-navbar") : setStickyClass("");
    }
  };

  useEffect(() => {
    // Event listener to close the menu when clicking outside of it
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setshow(false);
        // props.setZindexValue(0)
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const forSmall = () => {
    if (windowWidth) {
    }
  };

  return (
    // <div className='header-nav' style={{ position: 'fixed', top: 100, left: 0, backgroundColor: show ? 'rgba(0,0,0,0.7)' : 'rgba(0,0,0,0)', width: '100%', height: "100%",backgroundColor:'red' }}>
    <div
      style={{
        position: "fixed",
        top:
          windowWidth === 320
            ? "45px"
            : windowWidth === 375
            ? "30px"
            : windowWidth < 375
            ? "20px"
            : windowWidth < 440
            ? "0px"
            : windowWidth <= 480
            ? updateWidth
            : 0,
        left: 0,
        backgroundColor:
          show || props.showModalNew ? "rgba(0,0,0,0.7)" : "rgba(0,0,0,0)",
        width: "100%",
        height: show || props.showModalNew ? "100%" : "0%",
        zIndex:
          show || props.showModalNew
            ? `${props.zIndexValue}`
            : `${props.zIndexValue}`,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        props.setShowMOdalNew(false);
      }}
    >
      <header className="main_hom">
        <div
          className={`menuscontainer ${props.menuvalue}`}
          tabIndex={1}
          ref={menuRef}
          style={{
            width: "1%",
            backgroundColor:
              show || props.showModalNew ? "rgba(0,0,0,0)" : "white",
            marginRight: "auto",
            top: windowWidth <= 430 ? 20 : "",
          }}
        >
          <i
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setshow((prevShow) => !prevShow);
            }}
            className={`fas mybars b_div`}
          >
            <BarsOutlined />
          </i>

          {show === true || props.showModalNew ? (
            <div
              style={{
                marginLeft: -14,
                top: windowWidth === 320 ? -59 : windowWidth <= 375 ? "" : 24,
              }}
            >
              <div style={{ marginTop: 6 }} className={`${props.bgValue} `}>
                <ul className="hamburgrmenu">
                  <li
                    className="verdanaFont three"
                    onClick={() => {
                      props.demopage("DemoOne");
                      setshow(false);
                      props.setOpenMenuRefOrPOem(false);
                      props.setShowMOdalNew(false);
                      props.setIsReflectionOrPoem(!props.openMenuRefOrPOem);
                    }}
                  >
                    Blog
                  </li>
                  <li
                    className="verdanaFont five"
                    onClick={() => {
                      props.demopage("DemoThree");
                      setshow(false);
                      props.setOpenMenuRefOrPOem(!props.openMenuRefOrPOem);
                      props.setShowMOdalNew(false);
                    }}
                  >
                    Poems
                  </li>
                  <li
                    className="verdanaFont seven"
                    onClick={() => {
                      props.demopage("DemoFour");
                      setshow(false);
                      props.setOpenMenuRefOrPOem(!props.openMenuRefOrPOem);
                      props.setShowMOdalNew(false);
                    }}
                  >
                    Reflections
                  </li>
                  <li
                    className="verdanaFont three"
                    onClick={() => {
                      props.demopage("DemoSeven");
                      setshow(false);
                      props.setOpenMenuRefOrPOem(false);
                      props.setShowMOdalNew(false);
                      props.setIsReflectionOrPoem(false);
                    }}
                  >
                    Art
                  </li>
                  <li
                  className="verdanaFont three"
                  onClick={() => {
                    props.demopage("DemoEight");
                    setshow(false);
                    props.setOpenMenuRefOrPOem(false);
                    props.setShowMOdalNew(false);
                    props.setIsReflectionOrPoem(false);
                  }}
                >
                  Books
                </li>
                  <li
                    className="verdanaFont five"
                    onClick={() => {
                      props.demopage("DemoTwo");
                      setshow(false);
                      props.setOpenMenuRefOrPOem(false);
                      props.setShowMOdalNew(false);
                      props.setIsReflectionOrPoem(false);
                    }}
                  >
                    Fairytale
                  </li>
                  <li
                    className="verdanaFont seven"
                    onClick={() => {
                      props.demopage("DemoSix");
                      setshow(false);
                      props.setOpenMenuRefOrPOem(false);
                      props.setShowMOdalNew(false);
                      props.setIsReflectionOrPoem(false);
                    }}
                  >
                    About Me
                  </li>
                  <li
                    className="verdanaFont three"
                    onClick={() => {
                      props.demopage("DemoFive");
                      setshow(false);
                      props.setOpenMenuRefOrPOem(false);
                      props.setShowMOdalNew(false);
                      props.setIsReflectionOrPoem(false);
                    }}
                  >
                    Contact
                  </li>
                  {/*<li className="verdanaFont eight" onClick={()=>{
             setshow(false)
            }}>Extra 1</li>
            <li className="verdanaFont five" onClick={()=>{
              setshow(false)
            }}>Extra 2</li>*/}
                </ul>
              </div>
            </div>
          ) : (
            ``
          )}
          {localStorage.getItem("isLogged") === "yes" && show == true ? (
            <div
              style={{
                marginLeft: -14,
                top: windowWidth === 320 ? -59 : windowWidth <= 375 ? "" : 24,
              }}
            >
              <div style={{ marginTop: 6 }} className={`${props.bgValue} `}>
                <ul className="hamburgrmenu">
                  <Link to={"/blogcreation"}>
                    <li className="verdanaFont three">Blog</li>
                  </Link>
                  <Link to={"/poem"}>
                    {" "}
                    <li className="verdanaFont five">Poems</li>
                  </Link>
                  <Link to={"/reflections"}>
                    {" "}
                    <li className="verdanaFont seven">Reflections</li>
                  </Link>
                  <li
                    onClick={() => {
                      setshow(false);
                    }}
                    className="verdanaFont three"
                  >
                    Art
                  </li>
                  <li
                    onClick={() => {
                      setshow(false);
                    }}
                    className="verdanaFont three"
                  >
                    Books
                  </li>
                  <Link to={"/fairytale"}>
                    {" "}
                    <li className="verdanaFont five">Fairytale</li>
                  </Link>
                  <Link to={"/aboutme"}>
                    {" "}
                    <li className="verdanaFont seven">About Me</li>
                  </Link>

                  <Link to={""}>
                    <li className="verdanaFont three">Contact</li>
                  </Link>
                  {/*<li className="verdanaFont eight" onClick={()=>{
                setshow(false)
              }}>Extra 1</li>*/}
                  <li
                    onClick={(e) => {
                      localStorage.setItem("isLogged", isLogged);
                      setshow(false);
                      props.hideLogged(true);
                      e.preventDefault();
                    }}
                    className="verdanaFont five"
                  >
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            ``
          )}
        </div>
      </header>
    </div>
  );
};
export default Navbar;
