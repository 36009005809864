/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import DemoOne from "./demo1.js";
import DemoTwo from "./demo2.js";
import DemoThree from "./demo3.js";
import DemoFour from "./demo4.js";
import DemoFive from "./demo5.js";
import DemoSix from "./demo6.js";
import DemoSeven from "./demo7.js";
import Header from "./Components/Navbar/navbar";
import { Modal, Form, Input, Button, Card, Dropdown, Menu, Tag } from "antd";
import {
  CloseOutlined,
  LockOutlined,
  FilterOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "antd/dist/reset.css";
import logos from "../src/images/menu-frame/new_lg.PNG";
import logos_back from "../src/images/menu-frame/new_big_lg.PNG";
import { connect } from "react-redux";

const FormItem = Form.Item;

function Blog({ blogs }) {
  const [form] = Form.useForm();
  const [demo, setDemo] = useState("tomatoes");
  const [demoBg, setDemoBg] = useState("bg1");
  const [demoPara, setDemoPara] = useState("madeEvolve");
  const [menutype, setmenutype] = useState("first");
  const [size, setsize] = useState(20);
  const [demoFontColor, setDemoFontColor] = useState("#000000");
  const [demoContent, setDemoContent] = useState("DemoOne");
  const [visible, setVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [show1, setshow1] = useState(true);
  const [currentLineHeight, setCurrentLineHeight] = useState("0.8em");
  const [stickyClass, setStickyClass] = useState("");
  const [selectedFont, setSelectedFont] = useState("tomatoes");
  const [zIndexValue, setZindexValue] = useState(15);
  const [zIndexbool, setZindexbool] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isReflectionOrPoem, setIsReflectionOrPoem] = useState(false);
  const [openMenuRefOrPOem, setOpenMenuRefOrPOem] = useState(false);
  const [showModalNew, setShowMOdalNew] = useState(false);

  let isLogged = "yes";

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  useEffect(() => {
    const checkScroll = () => {
      const wrapHome = document.querySelector(".wrap_home");
      if (wrapHome.scrollHeight > wrapHome.clientHeight) {
        setStickyClass("scroll-indicator"); // Change or add your desired class
      } else {
        setStickyClass("");
      }
    };

    checkScroll();
    window.addEventListener("resize", checkScroll);

    return () => window.removeEventListener("resize", checkScroll);
  }, []);

  const handleChange = (e) => {
    setDemo(e.target.name);
  };
  const handleFontColor = (e) => {
    setDemoFontColor(e);
  };
  const handleBg = (e) => {
    setDemoBg(e.target.name);
  };
  const handlepara = (e) => {
    setDemoPara(e.target.name);
  };
  const handlemenu = (e) => {
    setmenutype(e.target.name);
  };
  const handlesize = (e) => {
    setsize(e.target.name);
  };
  let a = [];
  for (let i = 0; i < 99; i++) {
    a.push(
      <a class="dropdown-item" name={`${i}`} onClick={handlesize}>
        {i}
      </a>
    );
  }
  function change(e) {
    return;
  }
  const handleCancel = (e) => {
    setVisible(false);
  };
  const saveStorage = () => {
    localStorage.setItem("isLogged", isLogged);
  };

  async function onFinish(e) {
    setIsLoggedIn(true);
    setVisible(false);
    saveStorage();
    setIsLocked(false);
    setshow1(false);
    return;
  }
  function onFinishFailed(e) {
    return;
  }

  useEffect(() => {
    const checkScroll = () => {
      const wrapHome = document.querySelector(".wrap_home");
      if (wrapHome.scrollHeight > wrapHome.clientHeight) {
        setStickyClass("scroll-indicator"); // Change or add your desired class
      } else {
        setStickyClass("");
      }
    };

    checkScroll();
    window.addEventListener("resize", checkScroll);

    return () => window.removeEventListener("resize", checkScroll);
  }, []);

  return (
    <div style={{ overflowX: "visible" }}>
      <div className="header-container">
        <div className="marquee-container">
          <img
            src={logos_back}
            alt="Icon"
            width={300}
            height={110}
            className="marquee-image"
          />
        </div>
        <div onClick={() => setVisible(true)} className="buttonLogin"></div>
        <div className="logo-container">
          {!isReflectionOrPoem ? (
            <img
              src={logos}
              alt="Icon"
              width={100}
              height={100}
              className="logo-image"
            />
          ) : (
            <div className="logo-image"></div>
          )}
        </div>
      </div>
      <Header
        setIsReflectionOrPoem={setIsReflectionOrPoem}
        setShowMOdalNew={setShowMOdalNew}
        showModalNew={showModalNew}
        setOpenMenuRefOrPOem={setOpenMenuRefOrPOem}
        openMenuRefOrPOem={openMenuRefOrPOem}
        menuvalue={menutype}
        demopage={setDemoContent}
        isLoggedIn={isLoggedIn}
        isLoggedInSet={setIsLoggedIn}
        visible={setVisible}
        showLogged={show1}
        hideLogged={setshow1}
        isLocked={setIsLocked}
        bgValue={demoBg}
        zIndexValue={zIndexValue}
        setZindexValue={setZindexValue}
      />

      <div className="wrap_home">
        {demoContent === "DemoOne" ? (
          <DemoOne
            lineHeight={currentLineHeight}
            parasize={size}
            menuvalue={menutype}
            font_type={demo}
            bgValue={demoBg}
            bgparaValue={demoPara}
            colorFont={demoFontColor}
            setIsReflectionOrPoem={setIsReflectionOrPoem}
            setShowMOdalNew={setShowMOdalNew}
            showModalNew={showModalNew}
          />
        ) : null}
        {demoContent === "DemoTwo" ? (
          <DemoTwo
            lineHeight={currentLineHeight}
            parasize={size}
            menuvalue={menutype}
            font_type={demo}
            bgValue={demoBg}
            bgparaValue={demoPara}
            colorFont={demoFontColor}
          />
        ) : null}
        {demoContent === "DemoThree" ? (
          <DemoThree
            lineHeight={currentLineHeight}
            parasize={size}
            menuvalue={menutype}
            font_type={demo}
            bgValue={demoBg}
            bgparaValue={demoPara}
            colorFont={demoFontColor}
            setIsReflectionOrPoem={setIsReflectionOrPoem}
            setShowMOdalNew={setShowMOdalNew}
            showModalNew={showModalNew}
          />
        ) : null}
        {demoContent === "DemoFour" ? (
          <DemoFour
            lineHeight={currentLineHeight}
            parasize={size}
            menuvalue={menutype}
            font_type={demo}
            bgValue={demoBg}
            bgparaValue={demoPara}
            colorFont={demoFontColor}
            setIsReflectionOrPoem={setIsReflectionOrPoem}
            setShowMOdalNew={setShowMOdalNew}
            showModalNew={showModalNew}
          />
        ) : null}
        {demoContent === "DemoFive" ? (
          <DemoFive
            lineHeight={currentLineHeight}
            parasize={size}
            menuvalue={menutype}
            font_type={demo}
            bgValue={demoBg}
            bgparaValue={demoPara}
            colorFont={demoFontColor}
          />
        ) : null}
        {demoContent === "DemoSix" ? (
          <DemoSix
            lineHeight={currentLineHeight}
            parasize={size}
            menuvalue={menutype}
            font_type={demo}
            bgValue={demoBg}
            bgparaValue={demoPara}
            colorFont={demoFontColor}
          />
        ) : null}
        {demoContent === "DemoSeven" ? (
          <DemoSeven
            demopage={setDemoContent}
            lineHeight={currentLineHeight}
            parasize={size}
            menuvalue={menutype}
            font_type={demo}
            bgValue={demoBg}
            bgparaValue={demoPara}
            colorFont={demoFontColor}
          />
        ) : null}
        {demoContent === "DemoEight" ? (
          <DemoSeven
            demopage={setDemoContent}
            lineHeight={currentLineHeight}
            parasize={size}
            menuvalue={menutype}
            font_type={demo}
            bgValue={demoBg}
            bgparaValue={demoPara}
            colorFont={demoFontColor}
          />
        ) : null}
      </div>
      {visible ? (
        <Modal
          className="login_modal"
          title="Login"
          footer={null}
          open={true}
          onCancel={handleCancel}
          col
          size={30}
          closable={true}
        >
          <Form
            initialValues={{ remember: true }}
            name="forget_password"
            form={form}
            onFinish={onFinish}
            onChange={change}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            <div className="login_form">
              <div
                className="row"
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignSelf: "center",
                }}
              >
                <label>Password</label>
                <CloseOutlined
                  size={40}
                  style={{ marginBottom: "20px" }}
                  onClick={() => setVisible(false)}
                />
              </div>
              <FormItem
                rules={[
                  {
                    message: "password seems wrong",
                    validator: (_, value) => {
                      if (value == "jd@1234") {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("password not match");
                      }
                    },
                  },
                ]}
                name={`password`}
              >
                <Input
                  type={`password`}
                  placeholder="Please enter your password"
                />
              </FormItem>
            </div>

            <Button
              type="primary"
              className="login_submit_button"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form>
        </Modal>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => ({
  blogs: state.enroll.blogs,
});

export default connect(mapStateToProps)(Blog);
