import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin } from "antd";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention";
import axios from "axios";
import { Navigate, Link } from "react-router-dom";
import editimage from "./../../images/edit.png";
import deleteimage from "./../../images/delete.png";
import moment from "moment";
import { antIcon } from "../Loader/antIcon";

import { API_URL } from "../../config";
import { HomeOutlined } from "@ant-design/icons";
const FormItem = Form.Item;
let pageName = "about";

const Font = Quill.import("formats/font");
const FontSecond = Quill.import("formats/font");
Font.whitelist = [
  "madeEvolve",
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
];
FontSecond.whitelist = [
  "madeEvolve",
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
  "tuckers",
  "winterLove",
  "alishader",
  "serendipity",
  "bluehill",
  "bunchOfFlowers",
  "christmasInk",
  "fairygod",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "holeademobold",
  "gratefulblack",
  "bringheart",
  "eligatedemo",
  "Trykker",
  "Springtime",
  "LittleMermaid",
  "Lacabeza",
  "KhatijaCalligraphy",
  "GrasshopperRegular",
  "AbitSketChy",
  "CalligraphyStye",
  "League","Cutive","kgneatlyPrintedSpaced-Bold","Magra-bold","Magra-regular","Quiet-evening"
];
Quill.register(Font, true);
Quill.register(FontSecond, true);

var Size = Quill.import("formats/size");
Size.whitelist = [
  "4px",
  "5px",
  "6px",
  "7px",
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];

Quill.register(Size, true);

const Parchment = Quill.import("parchment");
const boxAttributor = new Parchment.Attributor.Class("box", "line", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["solid", "double", "dotted"],
});
Quill.register(boxAttributor);
const excludedFonts = [
  "tuckers",
  "winterLove",
  "alishader",
  "serendipity",
  "bluehill",
  "bunchOfFlowers",
  "christmasInk",
  "fairygod",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "spaceCraft",
  "holeademobold",
  "gratefulblack",
  "tomatoes",
  "bringheart",
  "eligatedemo","Cutive","kgneatlyPrintedSpaced-Bold","Magra-bold","Magra-regular","Quiet-evening","Kaylari","Kleymissky","BlackoutMidnight","HappyKids","SkrawkSerif"
];
// Filter the fonts to exclude those in the excludedFonts array
const filteredFonts = Font.whitelist.filter(
  (font) => !excludedFonts.includes(font)
);

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold" />
    <button className="ql-underline" />
    <button className="ql-italic" />
    <select className="ql-font">
      {filteredFonts.map((font, index) => (
        <option
          key={index}
          value={font}
          defaultValue={font.includes("verdana")}
        >
          {font[0].toUpperCase() + font.substr(1)}
        </option>
      ))}
    </select>
    <select className="ql-size">
      {Size.whitelist.map((size, index) => {
        const sizeWithoutPx = size.replace("px", "");
        return (
          <option key={index} value={size} defaultValue={size.includes("12")}>
            {sizeWithoutPx}
          </option>
        );
      })}
    </select>
  </div>
);

const modules = {
  toolbar: {
    container: "#toolbar",
  },
};

const formats = [
  "bold",
  "underline",
  "italic",
  "font",
  "size",
  "indent",
  "box",
  // "mention",
];

function AboutMe(props) {
  const [form] = Form.useForm();
  const [ids, setid] = useState("");
  const [form1] = Form.useForm();
  const [text_value, settext_value] = useState("");
  const [show, setshow] = useState(false);
  const [getdatas, setGetDatas] = useState([]);
  const [title_value, settitle_value] = useState("");
  const [image, setimage] = useState("");
  const [success, setsuccess] = useState(false);
  const [isupdate, setisupdate] = useState(false);
  const [forimageapi, setforimageapi] = useState("");
  const [visible, setVisible] = useState(false);
  const [quillInstance, setQuillInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [quillMessage, setQuillMessage] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .post(`${API_URL}/getAllPosts`)
      .then((res) => setGetDatas(res.data.blogInfo))
      .catch(() => alert("Something went wrong"));
  };

  function change(e) {
    return;
  }
  async function onFinish(e) {
    setshow(false);
    return;
  }
  function onFinishFailed(e) {
    return;
  }
  function onFinishblog(e) {
    setIsLoading(true);
    const quillContent = quillInstance.getContents(); // Get the latest editor content
    const htmlContent = quillInstance.root.innerHTML; // Get the HTML content if needed

    if (quillContent && quillContent.ops[0].insert.length === 1) {
      // Handle empty quillContent, for example, display an error message
      setQuillMessage("Quote is required");
      // You can return or perform any necessary action based on the empty content
      return;
    }
    let formData = new FormData();

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
    };
    formData.append("title", "");
    formData.append("image", forimageapi);
    formData.append("year", "");
    formData.append("place", "");
    formData.append("note", "");
    formData.append("background_colour", "");
    formData.append("Quote", htmlContent.length === 11 ? "" : htmlContent);
    formData.append("pageName", "about");
    if (isupdate == true) {
      formData.append("id", ids);
    } else {
    }
    const requestOptions = {
      method: "POST",
      header: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "*",
      },
      body: formData,
    };

    fetch(`${API_URL}/setBlogPost`, requestOptions)
      .then(() => {
        setsuccess(true);
        axios
          .post(`${API_URL}/getAllPosts`)
          .then((res) => {
            setGetDatas(res.data.blogInfo);
            setIsLoading(false);
          })
          .catch(() => {
            alert("something went wrong");
          });
      })
      .catch(() => {
        alert("something went wrong");
        setIsLoading(false);
      });
    return;
  }
  function onFinishFailedblog(e) {
    return;
  }
  function comes_here(e) {
    settext_value(e);
    return;
  }

  if (show == false) {
    return (
      <div className="main-wrapper">
        <Link to={"/"}>
          <button onClick={() => {}} className="home_button">
            <HomeOutlined style={{ color: "black" }} size={20} />
          </button>
        </Link>

        <div className="title-head text-center">
          <h1> Create Your Own About Me</h1>
        </div>
        <div
          className="about-wrappers"
          style={{
            backgroundColor: "white",
            alignSelf: "center",
            margin: "auto",
            borderRadius: "15px",
            boxShadow: "initial",
          }}
        >
          <Form
            initialValues={{ remember: true }}
            name="blog_creation"
            form={form1}
            onFinish={onFinishblog}
            onFinishFailed={onFinishFailedblog}
            layout="inline"
            style={{ width: "80%", margin: "auto", paddingTop: "20px" }}
          >
            <div className="form-group">
              <label>Profile Image</label>
              <FormItem name={`image`}>
                <input
                  type={`file`}
                  onChange={(e) => {
                    const reader = new FileReader();
                    setforimageapi(e.target.files[0]);
                    reader.onload = function (e) {
                      var file = reader.result || e.target.result;
                      setimage(file);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                  accept=".jpg,.png,.jpeg"
                  placeholder="Please enter your title"
                  className="blog-inputs"
                  style={{ width: "100%" }}
                />
              </FormItem>
            </div>
            <div className="form-group w-100">
              <label>Your Bio</label>
              <FormItem
                rules={[
                  {
                    required: false,
                    message: "Quote is required",
                  },
                ]}
                name={`Quote`}
              >
                <CustomToolbar />
                <ReactQuill
                  theme="snow"
                  value={text_value}
                  onChange={comes_here}
                  modules={modules}
                  formats={formats}
                  ref={(quill) => {
                    if (quill) {
                      setQuillInstance(quill.getEditor());
                    }
                  }}
                />
              </FormItem>
            </div>
            <div className="form-group w-100 text-center">
              {isLoading === true ? (
                <Spin indicator={antIcon} style={{ color: "white" }} />
              ) : (
                ""
              )}
              {getdatas.length != 0 && isupdate === true ? (
                <Button
                  type="primary"
                  className="login_submit_button"
                  htmlType="submit"
                >
                  {" "}
                  Update your about profile
                </Button>
              ) : null}
              {getdatas.filter((p) => p.pageName === pageName).length === 0 &&
              isupdate === false ? (
                <Button
                  type="primary"
                  className="login_submit_button"
                  htmlType="submit"
                >
                  {" "}
                  Create your about profile
                </Button>
              ) : null}
            </div>
          </Form>
        </div>
        {success == true ? (
          <Navigate
            to={{
              pathname: "/",
            }}
          />
        ) : (
          ""
        )}
        <div className="App">
          {getdatas.length > 0
            ? getdatas
                .filter((item) => item.pageName === "about")
                .map((item) => {
                  let date = item.created_at.split(" ")[0].split("-");
                  const month = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  date = `${
                    date[1] > 9 ? month[date[1] - 1] : month[date[1][1] - 1]
                  }   ${date[2] > 9 ? date[2] : `0${date[2]}`} ${date[0]}`;

                  return (
                    <>
                      <div className="container-fluid post-container">
                        <div class={`post-sec frame1`}>
                          <div
                            style={{
                              display: `flex`,
                              justifyContent: `flex-end`,
                            }}
                          >
                            <div>
                              <img
                                src={editimage}
                                id={item.id}
                                style={{
                                  width: `20px`,
                                  marginRight: `10px`,
                                  cursor: `pointer`,
                                }}
                                onClick={(e) => {
                                  form1.setFieldsValue({
                                    title: item.title,
                                    Quote: item.description,
                                  });
                                  setisupdate(true);
                                  setimage(item.imageUrl);
                                  settext_value(item.description);
                                  settitle_value(item.title);
                                  setforimageapi(item.imageUrl);
                                  setid(e.target.id);
                                  document.body.scrollTop = 0;
                                  document.documentElement.scrollTop = 0;
                                }}
                              />
                            </div>
                            <div>
                              <img
                                src={deleteimage}
                                style={{ cursor: `pointer`, width: `20px` }}
                                id={item.id}
                                onClick={(e) => {
                                  axios
                                    .post(`${API_URL}/deletePost`, {
                                      id: e.target.id,
                                    })
                                    .then((res) => {
                                      axios
                                        .post(`${API_URL}/getAllPosts`)
                                        .then((res) => {
                                          setGetDatas(res.data.blogInfo);
                                        })
                                        .catch(() => {
                                          alert("something went wrong");
                                        });
                                    });
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="date-created-hidden">
                            <p>
                              {moment(item.created_at)
                                ?.utcOffset(0, true)
                                .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                            </p>
                          </div>
                          <h1 class="entry-title">{item.title}</h1>
                          <div className="date-created">
                            <p>
                              {moment(item.created_at)
                                ?.utcOffset(0, true)
                                .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                            </p>
                          </div>
                          <div className="UserProfileCard">
                            {item.imageUrl ? (
                              <img
                                className={`image1`}
                                src={item.imageUrl}
                                style={{ width: "100%" }}
                              ></img>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="desc_wrapper_fairytale">
                            <div
                              style={{
                                color: `#000080`,
                                width: "98%",
                                display: "inline-block",
                                wordWrap: "break-word",
                                lineHeight: "0.2em",
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                              class={`entry-content `}
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
            : ""}
        </div>
      </div>
    );
  }
  return (
    <div>
      {visible ? (
        <Modal
          className="login_modal"
          title="Login"
          footer={null}
          visible={true}
          style={{ backgroundColor: "GrayText" }}
        >
          <Form
            initialValues={{ remember: true }}
            name="forget_password"
            form={form}
            onFinish={onFinish}
            onChange={change}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            <div className="login_form">
              <label>Password</label>
              <FormItem
                rules={[
                  {
                    message: "password seems wrong",
                    validator: (_, value) => {
                      if (value == "jd@1234") {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("password not match");
                      }
                    },
                  },
                ]}
                name={`password`}
              >
                <Input
                  type={`password`}
                  placeholder="Please enter your password"
                />
              </FormItem>
            </div>

            <Button
              type="primary"
              className="login_submit_button"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form>
        </Modal>
      ) : null}
    </div>
  );
}

export default AboutMe;
