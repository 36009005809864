import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ContentBlogLoader from "./Components/Loader/contentLoader";
import BodyBlogLoader from "./Components/Loader/bodyLoader";
import { fetchReflections } from "./Actions/reflections";
import images_nodata from "../src/images/new-menu-bg/images_nodata.png";
import Note from "./utils/Note";
import "./App.css";
import axios from "axios";
import { API_URL } from "./config";
import {
  Modal,
  Form,
  Input,
  Button,
  Card,
  Dropdown,
  Menu,
  Tag,
  Spin,
} from "antd";
import {
  CloseOutlined,
  LockOutlined,
  FilterOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import DOMPurify from "dompurify";
import logos from "../src/images/menu-frame/new_lg.PNG";

function DemoFour({
  parasize,
  menuvalue,
  font_type,
  bgValue,
  bgparaValue,
  colorFont,
  reflections,
  lineHeight,
  setIsReflectionOrPoem,
  setShowMOdalNew,
  showModalNew,
}) {
  // alert(parasize
  const [success, setsuccess] = useState([]);
  const [getdatas, setgetdatas] = useState([]);
  const currentYear = new Date().getFullYear(); //
  const [topImage_data, setTopImage_data] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tagOptions, setTagOptions] = useState([]); // Store tags dynamically from API
  const [loadingTags, setLoadingTags] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsReflectionOrPoem(true);
    dispatch(fetchReflections(setsuccess));
  }, []);

  useEffect(() => {
    if (reflections?.length !== 0) {
      setgetdatas(reflections);
      setsuccess(true);
    }
    axios
      .post(`${API_URL}/getAllPosts`)
      .then((res) => {
        // setsuccess(true)
        setTopImage_data(res.data.top_image);
      })
      .catch(() => {
        alert("something went wrong");
      });
  }, [reflections]);
  // Fetch tags on component mount
  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    setLoadingTags(true);
    try {
      const response = await axios.get(`${API_URL}/taglist`);
      if (response.data.result === "success") {
        setTagOptions(response.data.data.map((tag) => tag.tag_name)); // Store tag names dynamically
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
      setLoadingTags(false);
    }
  };

  const filteredBlogs = getdatas.filter((reflections) => {
    const sanitizedTitle = DOMPurify.sanitize(reflections.title).toLowerCase();
    const sanitizedDescription = DOMPurify.sanitize(
      reflections.description || ""
    ).toLowerCase();

    // Check for match in title or description
    const titleOrDescriptionMatch =
      sanitizedDescription.includes(searchTerm.toLowerCase()) ||
      sanitizedTitle.includes(searchTerm.toLowerCase());

    // Check for tags match
    const tagsMatch =
      selectedTags.length === 0 ||
      selectedTags.some((tag) => reflections.tags?.includes(tag));

    // Return true if both conditions are met
    return titleOrDescriptionMatch && tagsMatch;
  });

  const handleSearch = (value) => {
    setSearchTerm(value);
  };
  const handleMenuClick = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  // Dynamic tag menu
  const menu = (
    <Menu className="custom-menu">
      {loadingTags ? (
        <Menu.Item key="loading">
          <Spin size="small" />
        </Menu.Item>
      ) : (
        tagOptions.map((tag) => (
          <Menu.Item
            key={tag}
            onClick={() => handleMenuClick(tag)}
            className="menu-item"
          >
            {selectedTags.includes(tag) ? <Tag color="blue">{tag}</Tag> : tag}
          </Menu.Item>
        ))
      )}
    </Menu>
  );

  if (success == false) {
    return (
      <React.Fragment>
        <div className="contentLoader">
          <ContentBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div
        className="main_searchBox"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
        }}
      >
        {/* Logo Section */}
        <div
          className="logo-container-new"
          style={{ cursor: "pointer" }}
          onClick={() => setShowMOdalNew(!showModalNew)}
        >
          <img
            src={logos}
            alt="Logo"
            width={90}
            height={90}
            className="logo-image"
          />
        </div>

        {/* Search Box and Filter Section */}
        <div
          className="inner_searchBox"
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            gap: "10px",
          }}
        >
          {/* Search Input */}
          <Input.Search
            placeholder="Search qualities or select tags"
            onSearch={handleSearch}
            allowClear={true}
            prefix={<SearchOutlined style={{ color: "#0F307A" }} />}
            className="searchBoxSpan"
            style={{
              flex: 1,
              maxWidth: "600px",
              margin: "auto",
            }}
          />

          {/* Filter Dropdown */}
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              type="text"
              style={{
                color: "#0F307A",
                fontSize: "16px",
                padding: "0 12px",
                fontWeight: "500",
                display: "inline-flex",
                alignItems: "center",
                backgroundColor: "#fff",
                height: "40px",
              }}
            >
              <FilterOutlined style={{ marginRight: "4px" }} />
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>

        {/* Tags Section */}
      </div>
      <div className="App" style={{ overflowX: "auto", marginTop: 70 }}>
        {success == true
          ? filteredBlogs.map((item, index) => {
              const decodedContent = item.description.replace(/&nbsp;/g, " ");
              return (
                <React.Fragment>
                  <div key={index} className="container-fluid post-container">
                    <div className="row">
                      <div className={`site-content ${font_type}`}>
                        <div class={`post-sec frame1 ${bgValue}`}>
                          {index === 0 && topImage_data !== null ? (
                            <img
                              className={`image1`}
                              src={topImage_data}
                              loading="lazy"
                              srcSet={topImage_data}
                              style={{ marginBottom: 40 }}
                            ></img>
                          ) : (
                            <div></div>
                          )}

                          {(item?.pageName === "poems" && item.note !== null) ||
                          (item?.pageName === "reflections" &&
                            item.note !== null) ? (
                            <div
                              className="note-container"
                              style={{
                                paddingTop: 10,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {item.title !== null ? (
                                <h1
                                  class={`entry-title entry-title-reflect`}
                                  // style={{height:80}}
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                ></h1>
                              ) : null}
                              {(item?.pageName === "poems" &&
                                item.note !== null) ||
                              (item?.pageName === "reflections" &&
                                item.note !== null) ? (
                                <>
                                  {item?.pageName === "poems" ||
                                  item?.pageName === "reflections" ? (
                                    <div className="sticky-box">
                                      <Note
                                        note={item.note}
                                        bg_color={item.background_colour}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                            </div>
                          ) : (
                            <React.Fragment>
                            {item.title.length !== 11 ? (
                              <h1
                              style={{
                                paddingTop:
                                  getdatas[0]?.pageName === "fairytale" &&
                                  getdatas[0]?.title === null
                                    ? 0
                                    : 10,
                                    paddingTop: item?.pageName === "reflections"? 70:0,
                                  marginBottom: item?.pageName === "reflections"? 0 : 0,
                              }}
                              className={"entry-tile"}
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              ></h1>
                            ) : <div  style={{
                                paddingTop: item?.pageName === "reflections" ? 70:0
                            }}></div>}
                          </React.Fragment>
                          )}
                          {item.imageUrl ? (
                            <img className={`image1`} src={item.imageUrl}></img>
                          ) : (
                            ""
                          )}
                          <div className="desc_wrapper">
                            {/* {item?.pageName === "reflections" && item.note !== null? <div className="poem-style">
                              <div style={{ fontSize: `${parasize}px`,color:`#000080`,width:'100%', display: 'inline-block', wordWrap: 'break-word',alignSelf:'center',fontFamily:`madeEvolve`}} class={`entry-content`} dangerouslySetInnerHTML={{ __html: item.description }}>
                            
                              </div>
                              {item?.pageName === "reflections" ?
                                <div><Note note={item.note}/></div>:null
                              }
                              </div>:
                              <> */}
                            <div
                              style={{
                                fontSize: `${parasize}px`,
                                color: `#0F307A`,
                                width: "100%",
                                display: "inline-block",
                                whiteSpace: "pre-wrap",
                                alignSelf: "center",
                                // paddingTop: 30,
                                paddingBottom: 50,
                              }}
                              className={`entry-content ${bgparaValue}`}
                              dangerouslySetInnerHTML={{
                                __html: item.description.replace(
                                  /&nbsp;/g,
                                  " "
                                ),
                              }}
                            ></div>
                            {/* </>} */}
                          </div>
                        </div>
                        {item?.pageName === "reflections" ? (
                          <div
                            className={`poem-reflection-footer`}
                            style={{
                              fontSize: 12,
                              fontFamily: `madeEvolve`,
                            }}
                          >
                            <h>
                              © {currentYear} Ritu Dhillon All Rights Reserved.
                              {"     "}
                            </h>
                            <h>
                              {""}
                              {item.place ? `${item.place},` : ""}{" "}
                              {item.year ? item.year : ""}
                            </h>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div class="separator"></div>
                </React.Fragment>
              );
            })
          : ""}
        {filteredBlogs.length === 0 ? (
          <div
            className=""
            style={{
              alignSelf: "center",
              marginTop: 100,
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={images_nodata}
              alt=""
              width={100}
              height={100}
              style={{ margin: "auto" }}
            />
            <label
              style={{
                alignSelf: "center",
                marginTop: 30,
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              No Reflections found!
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  reflections: state.enroll.reflections,
});

export default connect(mapStateToProps)(DemoFour);
