import axios from "axios";
import { API_URL } from "../config";
import api from "../utils/api";
import { ADD_POEMS, FETCH_BLOGS_LIST, FETCH_POEMS_LIST } from "./actionTypes";


export const createPoems = (e,setsuccess,ids,setisupdate,isupdate,forimageapi,setIsLoading,quillContent,quillContentFonts,
  htmlContentFOnts,selectedColor,selectedCOntentColor,seletTagsList) => async dispatch => {
    setIsLoading(true)
    try {
      console.log("the printed line now",e.selectedFontSize);
      const encodedContent = e.descriptionHTML.replace(/ /g, '&nbsp;');
      let formData = new FormData();
          formData.append('title',htmlContentFOnts.length === 11 ? "" : htmlContentFOnts);
          formData.append('image',forimageapi)
          formData.append('place',typeof e.place === 'undefined'? "":e.place === null?"":  e.place)
          formData.append('year',e.year === null ? "":isNaN(e.year)?"": e.year)
          formData.append('Quote',encodedContent) 
          formData.append('background_colour',selectedColor)
          formData.append('fairytale_content_colour',selectedCOntentColor)
          formData.append('note',e.descriptionHTMLNote.length === 11 ? "":e.descriptionHTMLNote)
          formData.append('space_number', e.selectedFontSize === null ? "" : e.selectedFontSize);
          formData.append('pageName',"poems") 
        if(isupdate == true){
          formData.append('id',ids)
          // formData.append('image',location.state.image)
        }else{
          
        }
        formData.append(
          "tags",
          e.selectedTagIds && e.selectedTagIds.length > 0
            ? JSON.stringify(e.selectedTagIds)
            : e.seletTagsList && e.seletTagsList.length > 0
            ? JSON.stringify(e.seletTagsList)
            : e.tags
            ? JSON.stringify(e.tags)
            : [] // Default to empty string if everything is undefined or empty
        );
        
        
    const res = await axios.post(`${API_URL}/setBlogPost`, formData);
      dispatch({
        type: ADD_POEMS,
        payload: res.data.blogInfo
      });
      setsuccess(true)
      setIsLoading(false)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  };


  export const fetchPoems = (setsuccess) => async dispatch => {
    const pageName = "poems";
    try {
      const res = await api.post(`${API_URL}/getAllPosts`);
      let sortedPoems = res.data.blogInfo.filter(item => item.pageName === pageName).sort((a, b) => b.created_at.split('/').reverse().join().localeCompare(a.created_at.split('/').reverse().join()));
   
      dispatch({
        type: FETCH_POEMS_LIST,
        payload: sortedPoems
      });
      setsuccess(true)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  }

  export const deletePoems = (id,setdeletedSuccess) => async dispatch => {
    try {
      const response = await api.post(`${API_URL}/deletePost`,{id});
      if (response.ok) {
        let res = await response.json()
        if (res.status == 200) {
          setdeletedSuccess(true)
        }
        return res
      }
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  }